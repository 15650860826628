
.boton {
   
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
  }
  
  .boton a {
    text-decoration: none;
    background-image: linear-gradient(to right, #0c6832, #07b507);
    border: 2px dotted white;
    color: aliceblue;
    border-radius: 50%;
    padding: 30px;
    position: relative;
  }
  
  .boton a::after {
    position: absolute;
    content: '';
    bottom: -7px;
    top: -7px;
    left: -7px;
    right: -7px;
    background-color: transparent;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: 0px;
    opacity: 1;
    transform-origin: center;
    animation: anim 800ms linear infinite;
  }
  
  .boton a::before {
    position: absolute;
    content: '';
    bottom: -7px;
    top: -7px;
    left: -7px;
    right: -7px;
    background-color: transparent;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: 0px;
    opacity: 0.8;
    transform-origin: center;
    animation: anim 800ms linear 400ms infinite;
  }
  
  @keyframes anim {
    from {
      margin: 0px;
      opacity: 1;
    }
  
    to {
      margin: -15px;
      opacity: 0;
    }
  }
  
  span {
    color: #fff;
    font-family: sans-serif;
    font-weight: 600;
    margin-left: 20px;
  }
  
