
.banner-user{
  height: 550px;
  
}

@media screen and (max-width: 780px){
  .banner-user{
    height: 300px;
    z-index: -1;
  }
}
.banner-user-elem{
  text-align: center;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner-user-elem .banner-user-title{
  font-size: 32px;
  top: 40%;
}
.banner-user-elem .banner-user-text{
  top: 40%;
}
.banner-anim-elem .bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}